import {apiGatewayUrl as ApiUrl, HTTP_DELETE, HTTP_GET, HTTP_POST} from '../utils/Consts';
import AuthHeader from './AuthHeader';
import {clear as RemoveAllDataFromCookieAndLocalStorage, save as SaveToStorage} from './StorageService';
import {errorNotification, warningNotification} from '../utils/Notifications';
import {PagesEnums, SessionTypesEnums} from "../utils/CustomEnums";
import {StatusCodes} from 'http-status-codes';


const URL = ApiUrl;

class AuthService {
    logout() {
        fetch(URL + 'logout', {
            method: HTTP_DELETE, headers: AuthHeader(), mode: 'cors'
        }).then((response) => {
            if (response.ok) {
                RemoveAllDataFromCookieAndLocalStorage().then( () => {
                    window.location.href = PagesEnums.HOME.link;}
                );
            } else {
                errorNotification('default.unexpected.error', " ");
            }
        })
    }

    login(login, password) {
        fetch(URL + 'candidate/login', {
            method: HTTP_POST,
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: JSON.stringify({
                "login": login,
                "password": password
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else if (response.status && response.status === StatusCodes.UNAUTHORIZED) {
                    warningNotification('auth.failed', " ");
                } else {
                    errorNotification('default.unexpected.error', " ");
                }
            })
            .then(data => {
                if (data && data.content && data.content.token && data.content.refreshToken) {
                    console.log("then2" + data);
                    SaveToStorage('token', data.content.token, SessionTypesEnums.COOKIE);
                    SaveToStorage('refreshToken', data.content.refreshToken, SessionTypesEnums.COOKIE);
                    window.location.href = PagesEnums.MAIN.link;
                }
            });
    }

    checkToken = async () => {
        const response = await fetch(URL + 'check', {
            method: HTTP_GET, headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Token jest nieważny');
        }
    }

    refreshToken = async (token) => {
        const response = await fetch(URL + 'refresh', {
            method: HTTP_POST, headers: AuthHeader(), body: JSON.stringify({
                "refreshToken": token
            })
        });
        // if (response.ok) {
        //     return response.json();
        // } else {
        //     this.logout();
        // }
    }
}

export default new AuthService()