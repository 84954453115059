import { SessionTypesEnums } from "../utils/CustomEnums";
import { get } from "./StorageService";
import { CANDIDATE_PREFIX } from '../utils/Consts';
let token = require("jsonwebtoken");

export const getCredentials = () => {
    let jwt = get("token", SessionTypesEnums.COOKIE);
    if (jwt) {
        let credentials = token.decode(jwt);
        return credentials.userEmail;
    } else {
        return "";
    }
}

export const getId = () => {
    let jwt = get("token", SessionTypesEnums.COOKIE);
    if (jwt) {
        let credentials = token.decode(jwt);
        return credentials.userId;
    } else {
        return "";
    }
}

export const getIdWithoutPrefix = () => {
    // if(getId()) {
    //     let ids = getId().split('_');
    //     if(ids[0] === CANDIDATE_PREFIX) {
    //         return ids[1]
    //     } else {
    //         throw new Error()
    //     }
    // } else {
    //     throw new Error()
    // }
    return getId();
}

export const getRoles = () => {
    let jwt = get("token", SessionTypesEnums.COOKIE);
    if (jwt) {
        let credentials = [];
        credentials = token.decode(jwt);
        // let roleSet = [];
        // credentials.forEach(function (element) {
        //     roleSet.push(element.authority);
        // })
        let roleSet = credentials["userRoles"];
        return roleSet;
    } else {
        return null;
    }
}

export const hasActiveRole = (roleName) => {
    let roleSet = [];
    roleSet = getRoles();
    if (roleSet != null) {
        if (roleSet.includes(roleName)) {
            return true;
        } else return false;
    } else return false;
}