import { get as GetFromStorage } from './StorageService';
import { SessionTypesEnums } from '../utils/CustomEnums';

export default function AuthHeader() {
  let token = GetFromStorage('token', SessionTypesEnums.COOKIE);

  if (token) {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + token
    };
  } else {
    return {};
  }
}